import { initializeApp } from "firebase/app";
import { getMessaging, onBackgroundMessage } from "firebase/messaging/sw";


var firebaseConfig = {
    apiKey: "AIzaSyCJ88la-kfuseDjrvRmE6ekB5ZoEIhnXy8",
    authDomain: "ma-osp-ordermng.firebaseapp.com",
    databaseURL: "https://ma-osp-ordermng.firebaseio.com",
    projectId: "ma-osp-ordermng",
    storageBucket: "ma-osp-ordermng.appspot.com",
    messagingSenderId: "234275447504",
    appId: "1:234275447504:web:46b0170545f72f4d8734d5",
    measurementId: "G-GF5QLTDPN8"
};


if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
    
    const firebaseApp = initializeApp(firebaseConfig);
    //firebase.analytics();

    class CustomPushEvent extends Event {
        constructor(data) {
            super('push');

            Object.assign(this, data);
            this.custom = true;
        }
    }

    /*
     * Overrides push notification data, to avoid having 'notification' key and firebase blocking
     * the message handler from being called
     */
    self.addEventListener('push', (e) => {
        // Skip if event is our own custom event
        if (e.custom) return;

        // Kep old event data to override
        const oldData = e.data;

        // Create a new event to dispatch, pull values from notification key and put it in data key,
        // and then remove notification key
        const newEvent = new CustomPushEvent({
            data: {
                ehheh: oldData.json(),
                json() {
                    const newData = oldData.json();
                    newData.data = {
                        ...newData.data,
                        ...newData.notification,
                    };
                    delete newData.notification;
                    return newData;
                },
            },
            waitUntil: e.waitUntil.bind(e),
        });

        // Stop event propagation
        e.stopImmediatePropagation();

        // Dispatch the new wrapped event
        dispatchEvent(newEvent);
    });

    const messaging = getMessaging(firebaseApp);
    const notificationBroadcast = new BroadcastChannel('suppy-notifications');

    onBackgroundMessage(messaging, (payload) => {
        // console.log('[firebase-messaging-sw.js] Received background message ', payload); // debug info

        const { title, body, ...restPayload } = payload.data;

        const notificationOptions = {
            body,
            data: restPayload,
        };

        notificationBroadcast.postMessage({
            id: typeof payload.data["google.c.a.c_id"] != 'undefined' ? payload.data["google.c.a.c_id"] : (Math.random() * 10000),
            title: payload.data.title,
            body: payload.data.body,
            data: typeof (payload.notification) != 'undefined' ? payload.notification.data : undefined
        });

        return self.registration.showNotification(title, notificationOptions);
    });

    self.addEventListener('notificationclick', (event) => {
        // console.log('[firebase-messaging-sw.js] notificationclick ', event); // debug info

        // click_action described at https://github.com/BrunoS3D/firebase-messaging-sw.js#click-action
        if (event.notification.data && event.notification.data.click_action) {
            self.clients.openWindow(event.currentTarget.origin + event.notification.data.click_action);
        } else {
            self.clients.openWindow(event.currentTarget.origin);
        }

        // close notification after click
        event.notification.close();
    });
}